/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { price: 10000, get: 10000 },
    { price: 30000, get: 30000 },
    { price: 50000, get: 50000 },
    { hot: 0, price: 100000, more: 2, get: 102000 },
    { hot: 0, price: 200000, more: 2, get: 204000 },
    { hot: 0, price: 300000, more: 2.5, get: 307500 },
    { hot: 0, price: 500000, more: 3, get: 515000 },
    { hot: 0, price: 1000000, more: 4, get: 1040000 },
    { hot: 0, price: 2000000, more: 4, get: 2080000 },
    { hot: 0, price: 5000000, more: 5, get: 5250000 }
  ],
  firstRechargeAmount: 5000,
  //限时活动
  limitDiscounts: 30000,
  firstRechargeAmountSecond: 10000,
  firstRechargedWithdrawNeedBets: 20000,
  defaultDepositGot: 100,
  defaultDepositPrice: 99,
  minDepositAmount: 10000,
  maxDepositAmount: 2000000,
  withdrawFeePercent: 0,
  minWithdrawAmount: 10000,
  maxWithdrawAmount: 1200000,
  maxWithdrawDailyAmount: 6000000,
  inviteWheel: {
    targetAmount: 100000,
  },
  luckyDraw: {
    marqueeAmoundList: [120, 480, 1200, 300, 30, 120, 48, 600, 480, 300, 3000, 600, 480, 60, 300, 30, 4800, 48, 60, 12],
  },
  addHomeScreenRewardFrom: 3,
  addHomeScreenRewardTo: 600,
  gasBurnRatio: 0,
  betGasRatio: 1,
  depositGasRatio: 0,
  useWithdrawLevel: 0,
};
