export default {
  games: [
    {
      id: 118,
      name: "Cooking Party",
      img: "games/118_cooking_party_luka.jpg",
      type: 3,
      query: "?game_id=118",
      online_level: 2,
    },
    {
      id: 119,
      name: "Fruit Mary",
      img: "games/119_fruit_slots_luka.jpg",
      type: 3,
      query: "?game_id=119",
      online_level: 2,
    },
    {
      id: 201,
      name: "Fortune Spin",
      img: "games/201_frtune_spin_luka.jpg",
      type: 3,
      query: "?game_id=201",
      online_level: 1,
    },
    {
      id: 202,
      name: "Super Wheel",
      img: "games/202_super_wheel_luka.jpg",
      type: 3,
      query: "?game_id=202",
      isHot: true,
      online_level: 2,
    },
    {
      id: 124,
      name: "Prairie Lion",
      img: "games/124_lion_luka.jpg",
      type: 3,
      query: "?game_id=124",
      online_level: 3,
    },
    {
      id: 203,
      name: "Crazy Roulette",
      img: "games/203_crazy_roulette_luka.jpg",
      type: 3,
      query: "?game_id=203",
      hide_demo: true,
      online_level: 2,
    },
    {
      id: 204,
      name: "Western Cash",
      jackpot_from_to: [100000, 1000000],
      img: "games/204_hot777_luka.jpg",
      type: 3,
      query: "?game_id=204",
      online_level: 3,
    },
    {
      id: 205,
      name: "Big Wheel",
      jackpot_from_to: [100000, 1000000],
      img: "games/205_spin_big_luka.jpg",
      type: 3,
      query: "?game_id=205",
      online_level: 3,
    },
    {
      id: 206,
      name: "Fortune Dice",
      img: "games/206_dice_luka.jpg",
      type: 5,
      query: "/dice",
      hide_demo: true,
      online_level: 2,
    },
    {
      id: 207,
      name: "Football 777",
      jackpot_from_to: [100000, 1000000],
      img: "games/209_football777_luka.jpg",
      type: 3,
      query: "?game_id=207",
      online_level: 3,
    },
    {
      id: 208,
      name: "Football Honor",
      jackpot_from_to: [100000, 1000000],
      img: "games/208_football_luka.jpg",
      type: 3,
      query: "?game_id=208",
      online_level: 3,
    },
    {
      id: 209,
      name: "Champion Team",
      img: "games/210_championteam_luka.jpg",
      type: 3,
      query: "?game_id=209",
      online_level: 3,
    },
    {
      id: 210,
      name: "Rich Or Crash",
      img: "games/211_rich_or_crash_luka.jpg",
      type: 5,
      query: "/crash",
      hide_demo: true,
      online_level: 2,
    },
    {
      id: 211,
      name: "Football Trip 10000X",
      img: "games/207_10000x_luka.jpg",
      type: 5,
      query: "/crash_football",
      hide_demo: true,
      online_level: 3,
    },
    {
      id: 212,
      name: "Penalty Taker",
      img: "games/212_penalty_taker_luka.jpg",
      type: 3,
      query: "?game_id=212",
      online_level: 3,
    },
    {
      id: 213,
      name: "SPIN UP",
      img: "games/213_spin_up_luka.jpg",
      type: 3,
      query: "?game_id=213",
      online_level: 2,
    },
    {
      id: 214,
      name: "One card fight",
      img: "games/214_one_card_fight_luka.jpg",
      type: 3,
      query: "?game_id=214",
      online_level: 3,
    },
    {
      id: 215,
      name: "Destiny Wheel",
      img: "games/215_destiny_wheel_luka.jpg",
      type: 3,
      query: "?game_id=215",
      online_level: 2,
    },
    {
      id: 216,
      name: "Dragon's Treasure",
      img: "games/216_crazy_disco_luka.jpg",
      type: 3,
      query: "?game_id=216",
      online_level: 3,
    },
    {
      id: 217,
      name: "Slots Crazy",
      jackpot_from_to: [100000, 1000000],
      img: "games/217_slots_crazy_luka.jpg",
      type: 3,
      query: "?game_id=217",
      online_level: 1,
    },
    {
      id: 218,
      name: "Mermaid Clots",
      img: "games/218_fishing_clash_luka.jpg",
      type: 3,
      query: "?game_id=218",
      online_level: 3,
    },
    {
      id: 219,
      name: "Gorilla King",
      img: "games/219_secret_garden_luka.jpg",
      type: 3,
      query: "?game_id=219",
      online_level: 3,
    },
    {
      id: 220,
      name: "X10000 Super Poker",
      img: "games/220_jackpot_x10000_luka.jpg",
      type: 3,
      query: "?game_id=220",
      online_level: 3,
    },
    {
      id: 221,
      name: "Ninja Monogatari",
      img: "games/221_volcanic_wealth_luka.jpg",
      type: 3,
      query: "?game_id=221",
      online_level: 3,
    },
    {
      id: 222,
      name: "Lucky Mines",
      img: "games/222_lucky_mines_luka.jpg",
      type: 3,
      query: "?game_id=222",
      isHot: true,
      online_level: 1,
    },
    {
      id: 223,
      name: "Free Spin",
      img: "games/223_free_spin_luka.jpg",
      type: 3,
      query: "?game_id=223",
      isHot: true,
      online_level: 1,
    },
    {
      id: 224,
      name: "Lucky Dice",
      img: "games/224_lucky-dice_luka.jpg",
      type: 3,
      query: "?game_id=224",
      online_level: 3,
    },
    {
      id: 225,
      name: "Scratch Cards",
      img: "games/225_scratch-cards_luka.jpg",
      type: 3,
      query: "?game_id=225",
      online_level: 2,
    },
    {
      id: 227,
      name: "Red-Black Clash",
      img: "games/227_red-black-clash_luka.jpg",
      type: 3,
      query: "?game_id=227",
      isHot: true,
      online_level: 0,
    },
    {
      id: 228,
      name: "Dragon VS Tiger",
      img: "games/228_dragon_vs_tiger_luka.jpg",
      type: 3,
      query: "?game_id=228",
      isHot: true,
      online_level: 0,
    },
    {
      id: 229,
      name: "Best Crash",
      img: "games/229_best_crash_luka.jpg",
      type: 3,
      query: "?game_id=229",
      hide_demo: true,
      online_level: 3,
    },
    {
      id: 230,
      name: "Zombie Town",
      img: "games/230_zombie-town_luka.jpg",
      type: 3,
      query: "?game_id=230",
      online_level: 3,
    },
    {
      id: 232,
      name: "Zeus",
      img: "games/232_zeus_luka.jpg",
      type: 3,
      query: "?game_id=232",
      hide_demo: true,
      isHot: true,
      online_level: 1,
    },
    {
      id: 234,
      name: "Sex Crash",
      img: "games/234_sex-crash_luka.jpg",
      type: 3,
      query: "?game_id=234",
      isHot: true,
      hide_demo: true,
      online_level: 1,
    },
    {
      id: 235,
      name: "Gates Of Gatotkaca",
      img: "games/235_gates_of_gatotkaca_luka.jpg",
      type: 3,
      query: "?game_id=235",
      isHot: true,
      online_level: 1,
    },
    {
      id: 54,
      name: "Fantasy Fishing",
      img: "games/54_fantasy-fishing_luka.jpg",
      type: 3,
      query: "?game_id=54",
      hide_demo: true,
      online_level: 3,
    },
    {
      id: 1001,
      name: "Rummy",
      img: "games/1001_rummy_luka.jpg",
      type: 20,
      query: "",
      jiliInfo: {
        gameId: 94,
      },
      hide_demo: true,
      online_level: 3,
    },
    {
      id: 1002,
      name: "TeenPatti",
      img: "games/1002_teenpatti_luka.jpg",
      type: 20,
      query: "",
      jiliInfo: {
        gameId: 72,
      },
      hide_demo: true,
      online_level: 3,
    },
  ],
  // games_originals: [1001, 1002, 228, 54, 217, 223],
  // games_originals_b: [1001, 1002, 228, 54, 217, 223],
  // games_originals: [228, 227, 234, 232, 222, 223],
  // games_originals_b: [228, 227, 234, 232, 222, 223],
  games_popular: [235, 215, 207, 229, 119, 222, 219, 211, 220, 213],
  games_popular_b: [235, 215, 207, 229, 119, 222, 219, 211, 220, 213],
  games_spin: [201, 223, 202, 205, 215, 213, 221, 203],
  games_football: [211, 208, 209, 207, 212],
  games_table_games: [212, 203, 214, 229, 210],
  games_instant_games: [206, 222, 224, 225, 227, 228],
  games_slots: [204, 207, 208, 209, 216, 217, 218, 118, 119, 219, 220, 230, 235],
  games_no_invite: [234, 229],
};
